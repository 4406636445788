// @ts-nocheck
jQuery(function ($) {
  // kebabwcjs is required to continue, ensure the object exists
  if (typeof kebabwcjs === "undefined") {
    return false;
  }

  // Супер Аякс
  var KebabAjaxQueue = $({});

  $.KebabAjaxQueue = function( ajaxOpts ) {
      var jqXHR,
          dfd = $.Deferred(),
          promise = dfd.promise();

      // run the actual query
      function doRequest( next ) {
          jqXHR = $.ajax( ajaxOpts );
          jqXHR.done( dfd.resolve )
              .fail( dfd.reject )
              .then( next, next );
      }

      // queue ajax request
      KebabAjaxQueue.queue( doRequest );

      // add the abort method
      promise.abort = function( statusText ) {

          // proxy abort to the jqXHR if it is active
          if ( jqXHR ) {
              return jqXHR.abort( statusText );
          }

          // if there wasn't already a jqXHR we need to remove from queue
          var queue = KebabAjaxQueue.queue(),
              index = $.inArray( doRequest, queue );

          if ( index > -1 ) {
              queue.splice( index, 1 );
          }

          // and then reject the deferred
          dfd.rejectWith( ajaxOpts.context || ajaxOpts, [ promise, statusText, "" ] );
          return promise;
      };

      return promise;
  };

  // Вспомогательные функции
  var get_url = function (endpoint) {
    return kebabwcjs.wc_ajax_url.toString().replace(
      "%%endpoint%%",
      endpoint,
    );
  };

  var sendYm = function(id) {
    if (typeof ym !== 'undefined') {
      ym(38081450, 'reachGoal', id);
    }
  };

  // 1) Карточка товара
  // 2) Корзина на странице оформления
  var KebabWC = {
    updateTimeout: null,
    $cart_total: $(".cart-total"),
    $checkout_form: $("form.checkout"),
    $window: $(window),
    $document: $(document),
    $documentBody: $(document.body),
    init: function () {
      var self = this;

      // Remove novalidate from checkout form
      // Fix HTML5 validation on phone input
      if( this.$checkout_form ) {
        this.$checkout_form.removeAttr('novalidate');
      }

      $.blockUI.defaults.overlayCSS.cursor = "default";

      this.$document.on( "change", ".qty", function(evt){
        evt.preventDefault();

        var min = parseFloat( $( this ).attr( 'min' ) );
        var max = parseFloat( $( this ).attr( 'max' ) );

        if ( min && min > 0 && parseFloat( $( this ).val() ) < min ) {

          $( this ).val( min );

        }else if ( max && max > 0 && parseFloat( $( this ).val() ) > max ) {

          $( this ).val( max );

        }

        var qty = $(this).val();
        var key = $( this ).attr( 'name' ).replace(/cart\[([\w]+)\]\[qty\]/g, "$1");
        var product_id = $( this ).data('product_id');

        self.updateProduct(key, qty, product_id);
      });

      this.$document.on("click", ".plus, .minus", function (evt) {
        evt.preventDefault();
        // Get elements and values
        var $this = $( this ),
            $qty = $this.parent().find( ".qty" ),
            currentVal = parseFloat( $qty.val()  ),
            max = parseFloat( $qty.attr( "max" ) ),
            min = parseFloat( $qty.attr( "min" )),
            step = $qty.attr( "step" ),
            newQty = currentVal;

        // Format values
        if (!currentVal || currentVal === "" || currentVal === "NaN") {
          currentVal = 0;
        }
        if (max === "" || max === "NaN") {
          max = "";
        }
        if (min === "" || min === "NaN") {
          min = 0;
        }
        if (
          step === "any" || step === "" || step === undefined ||
          parseFloat(step) === "NaN"
        ) {
            step = 1;
        }

        // Change the value
        if ( $this.is( ".plus" ) ) {

          if ( max && ( max === currentVal || currentVal > max ) ) {
            newQty = ( max );
            $qty.val( max );
          } else {
            newQty = ( currentVal + parseFloat( step ) );
            $qty.val( currentVal + parseFloat( step ) );
          }

          sendYm("addeditem");

        } else {

          if ( min && ( min === currentVal || currentVal < min ) ) {
            newQty = ( min );
            $qty.val( min );
          } else if (currentVal > 0) {
            newQty = ( currentVal - parseFloat( step ) );
            $qty.val( currentVal - parseFloat( step ) );
          }

        }

        // Trigger change event
        var key = $qty.attr( 'name' );
        var product_id = $qty.data('product_id');
        $qty.trigger('change');

        if(currentVal !== newQty) {

          // Update product quantity
          self.updateProduct(key, newQty, product_id);
        }
      });
    },

    request: function(type, args, callback) {
      var self = this;

      if(type === 'totals') {
        this.refreshFragments(type, callback);
        return false;
      }

      var params = {
        type: type
      };

      params = $.extend(params, args);

      $.KebabAjaxQueue({
        url: get_url('update_cart_kebab'),
        data: params,
        type: 'POST'
      }).done(function(data) {

        self.onRequestDone(data, type, callback);
      });
    },

    refreshFragments: function(type,callback ) {
      var self = this;

      $.KebabAjaxQueue({
        url: get_url('get_refreshed_fragments'),
        data: {
          type: type
        },
        type: 'POST'
      }).done(function(data) {

        self.onRequestDone(data, type, callback);
      });
    },

    onRequestDone: function(data, type, callback) {
      if ( data && data.fragments ) {

        $.each( data.fragments, function( key ) {
          $( key )
            .addClass( 'updating' )
            .fadeTo( '400', '0.6' )
            .block({
              message: null,
              overlayCSS: {
                opacity: 0.6
              }
            });
        });

        $.each( data.fragments, function( key, value ) {
          $( key ).replaceWith( value );
          $( key ).stop( true ).css( 'opacity', '1' ).unblock();
        });

        this.$documentBody.trigger( 'wc_fragments_refreshed' );

        if( typeof( callback ) !== 'undefined' ) {
          callback( data );
        }

        /*$.each( data.fragments, function ( key, value ) {
          $( key ).replaceWith( value );
        } );

        this.$document.trigger( 'wc_fragments_refreshed' );*/
      }
    },
    updateProduct: function(key, qty, product_id, callback) {
      if(qty > 0) {

        this.request('update', {

          cart_item_key: key,
          cart_item_qty: qty,
          cart_product_id: product_id

        }, function(data) {

          $( document.body ).trigger( 'kebab_product_update', [ key, qty, product_id ] );

          if(typeof(callback) !== 'undefined') {
            callback(data);
          }

        });

      }else{
        this.removeProduct(key, callback);
      }
    },
    removeProduct: function(key, qty, callback) {
      var self = this;

      this.request('remove', {

        cart_item_key: key

      }, function() {

        $( document.body ).trigger( 'kebab_product_removed', [ key ] );

        if(typeof(callback) !== 'undefined') {
          callback();
        }
      });
    }

  };

  KebabWC.init();
});

